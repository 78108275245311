import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://52e33244f7ff4efcbdccc123682801e2@o4504668514156544.ingest.sentry.io/4504668687892480",
  tracesSampleRate: 1.0, // Adjust this value in production
  beforeSend(event) {
    // Modify the event here
    if (event.user) {
      // Don't send user's email address
      delete event.user.email;
    }
    return event;
  },
  // ...
});
